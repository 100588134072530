<!-- @format -->

<template>
  <div>
    <TableSkeletonLoader
      v-if="props.loader"
      :loader="props.loader"
      :cols="5"
      :rows="4"
    />
    <table v-else class="w-full">
      <thead class="bg-teal text-left">
        <th
          v-for="(heading, id) in tableHeading"
          :key="id"
          class="px-6 py-4 text-white font-thin text-sm"
        >
          {{ heading }}
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(data, id) in tableBody"
          :key="id"
          class="border bottom-1 text-left"
        >
          <td class="px-6 py-5 font-thin text-sm max-clamp truncate capitalize">
            {{ data?.first_name + " " + data?.last_name }}
          </td>
          <td class="px-6 py-5 font-thin text-sm max-clamp truncate capitalize">
            {{ data?.medical_letter?.name }}
          </td>
          <td class="px-6 py-5 font-thin text-sm max-clamp truncate">
            {{
              moment(data?.medical_letter?.created_at).format("MMM DD, YYYY")
            }}
          </td>
          <td class="px-6 py-5 font-thin text-sm">
            <span
              class="px-4 rounded-lg py-1"
              :class="
                data?.status == 0
                  ? 'bg-blue bg-opacity-10 text-blue'
                  : data?.status == 1
                  ? 'bg-teal bg-opacity-10 text-teal'
                  : 'bg-red bg-opacity-10 text-red'
              "
              >{{
                data?.status == 0
                  ? "Pending"
                  : data?.status == 1
                  ? "Approved"
                  : "Rejected"
              }}</span
            >
          </td>

          <td class="w-28">
            <span
              class="border-teal border px-2 py-1 font-thin text-sm rounded-lg text-teal cursor-pointer hover:bg-teal hover:text-white"
              @click="navigate(data?.id)"
            >
              View Here
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { defineProps, toRefs } from "vue";
import { useRouter } from "vue-router";

import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";
import moment from "moment";

const props = defineProps({
  tableHeading: {
    type: Array,
    required: true,
  },
  tableBody: {
    type: Array,
    required: true,
  },
  loader: {
    type: Boolean,
  },
});

const { tableBody, tableHeading } = toRefs(props);

const router = useRouter();

const navigate = (letterId) => {
  router.push({
    name: "viewMedicalLetter",
    params: { letterId: letterId },
  });
};
</script>
