<!-- @format -->
<template>
  <div>
    <span class="text-2xl font-medium">Medical Letters</span>
    <div
      class="relative overflow-x-auto rounded-xl bg-white shadow-md my-5 overflow-hidden"
    >
      <div class="p-6 flex justify-between items-center">
        <div>
          <h4 class="text-xl">Certificate Requests</h4>
        </div>
        <div class="w-1/3 flex gap-4">
          <v-select
            v-model="certificateName"
            label="name"
            placeholder="Select Certificate"
            :options="typesOfCertificates"
            @update:modelValue="choosenCertificate"
            class="w-1/2"
          ></v-select>
          <DatePicker
            :selectedDate="filterDate"
            @selected-date="atFilterDate"
            @cleared-date="atFilterDate"
            :needMaxDate="true"
            :maxDate="date"
            placeholder="Date"
            class="w-1/2 border border-gray-200 rounded"
          />
        </div>
      </div>
      <SearchField
        @input="searchCertificates"
        placeholder="Search By Patient Name"
        class="border-t border-gray-100"
      />
      <div
        class="drop-shadow-2xl w-full px-6 py-3 flex justify-between border-t border-l-0 border-r-0 border-b-0 border border-gray-200"
      >
        <!-- {{ lettersRequests.counts }} -->
        <Tabs
          :bottomBorder="false"
          :tabsData="tabsData"
          :activeTabIndex="tabIndex"
          @index="switchTab"
          :key="lettersRequests.counts"
        />
      </div>
      <div>
        <MedicalLetterRequestTable
          :tableHeading="tableHeading"
          :tableBody="lettersRequests?.records?.data"
          :loader="loading"
          :key="tabIndex"
        />
        <Pagination
          :currentPage="lettersRequests?.records?.current_page"
          :totalPages="lettersRequests?.records?.last_page"
          @page-changed="atPageChange"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
import SearchField from "@/components/newUi/baseComponents/SearchField.vue";
import MedicalLetterRequestTable from "../../../components/newUi/tables/MedicalLetterRequestTable.vue";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
const tabIndex = ref(0);
const date = new Date();

const store = useStore();
const certificateName = ref(null);
const loading = ref(false);
const page = ref(1);
const searchField = ref("");
const typesOfCertificates = ref([]);
const selectedCertificate = ref();

const tableHeading = [
  "Patient Name",
  "Certificates",
  "Date",
  "Status",
  "Details",
];

const searchCertificates = (searchText) => {
  searchField.value = searchText;
  page.value = 0;
};

const lettersRequests = computed(() => {
  return store.getters["PMLModule/getMedicalLettersRequests"];
});

const tabsData = computed(() => {
  const counts = lettersRequests.value?.counts || {
    pending: 0,
    approved: 0,
    rejected: 0,
  };

  return [
    { name: `Pending (${counts.pending})`, value: 0 },
    { name: `Approved (${counts.completed})`, value: 1 },
    { name: `Rejected (${counts.rejected})`, value: 2 },
  ];
});

const switchTab = (index) => {
  tabIndex.value = index;
};
const filterDate = ref(null);

watchEffect(async () => {
  if (tabIndex.value !== undefined) {
    loading.value = true;
    const payload = {
      status: tabIndex.value,
      date: filterDate.value,
      page: page.value,
      search: searchField.value,
      medicalLetter: selectedCertificate.value,
    };
    await store
      .dispatch("PMLModule/fetchMedicalLettersRequests", payload)
      .then(() => {
        loading.value = false;
      });
  }
});

const atFilterDate = (selectedDate) => {
  if (selectedDate) {
    filterDate.value = selectedDate;
  } else {
    filterDate.value = null;
  }
  page.value = 0;
};

const choosenCertificate = (certificate) => {
  console.log("here is the certificate", certificate);

  if (certificate) {
    selectedCertificate.value = certificate.id;
  } else selectedCertificate.value = null;
  page.value = 0;
};

const atPageChange = (pageNumber) => {
  page.value = pageNumber;
};
onMounted(() => {
  store.dispatch("PMLModule/fetchAllMedicalLetters").then((response) => {
    typesOfCertificates.value = response;
  });
});
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
  color: white;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
::v-deep ul li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
